import https from "./https";
import store from "@/store";

const shop = {
  async getProducts(params = {}) {
    const organization = store.state.liffGeneral.orgCode

    let response = await https.get(`${organization}/liff/shop/products`, { params })
    return response.data.data
  },

  async getProduct(id) {
    const organization = store.state.liffGeneral.orgCode

    let response = await https.get(`${organization}/liff/shop/products/${id}`)
    return response.data
  },
};

export default shop
